export default {
	cinema: {
		address: null,
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: null,
		selectButton: {
			options: [
				{
					value: '//druzhba.kino-khv.ru',
					title: <img src="/img/menu-drujba.svg" alt="Дружба" />,
				},
				{
					value: '//four-room.kino-khv.ru',
					title: <img src="/img/menu-four.svg" alt="Four Room" />,
				},
				{
					value: '//habarovsk.kino-khv.ru',
					title: <img src="/img/menu-habar.svg" alt="Хабаровск" />,
				},
				{
					value: '//kosmoport.kino-khv.ru',
					title: <img src="/img/menu-kosmo.svg" alt="Космопорт" />,
				},
				{
					value: '//kino-khv.ru',
					title: <img src="/img/menu-atmo.svg" alt="Атмосфера" />,
				},
			],
			selectedOptionValue: '//kosmoport.kino-khv.ru',
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
